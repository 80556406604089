import React, { useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import Logo from "../assets/images/72.png";
import "./home.css";
import { AdminAccessList, formatUserName, RegistrarAccessList, USER_ROLE } from "../utils";
import useModuleAccess from "../helpers/hooks/useModuleAccess";
import packageJson from "../../package.json";
import { KEYS } from "../helpers/constants";
const Homez = () => {
  const nav = useNavigate();
  const { checkModuleAccess } = useModuleAccess();

  const handleLogout = () => {
    localStorage.removeItem(KEYS.user);
    localStorage.removeItem(KEYS.birthToken);
    nav("/unauth");
  };
  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);
  const userInfo = JSON.parse(localStorage.getItem(KEYS.user));
  const hasAdminAccess = checkModuleAccess(AdminAccessList);
  const hasRegistrarAccess = checkModuleAccess(RegistrarAccessList);
  const token = localStorage.getItem(KEYS.birthToken);

  const [isModificationsOpen, setIsModificationsOpen] = useState(false);
  const [isOtherAccessOpen, setIsOtherAccessOpen] = useState(false);
  const [isBirthCertReissuanceOpen, setIsBirthCertReissuanceOpen] = useState(false);

  const toggleModificationsMenu = () => {
    setIsModificationsOpen(!isModificationsOpen);
  };

  const toggleOtherAccessMenu = () => {
    setIsOtherAccessOpen(!isOtherAccessOpen);
  };

  const toggleBirthCertReissuanceMenu = () => {
    setIsBirthCertReissuanceOpen(!isBirthCertReissuanceOpen);
  };

  return (
    <div className="min-h-screen flex relative lg:static surface-ground">
      <div
        id="app-sidebar-1"
        className="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:sticky left-0 top-0 z-2 select-none c-shadow"
        style={{ width: "300px" }}
      >
        <div
          className="flex flex-column h-full"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div
            className="flex flex-column align-items-center justify-content-center mt-3"
            style={{ backgroundColor: "white" }}
          >
            <img src={Logo} alt="Vital Reg Logo" height="45" width="50" className="mb-2" />
            <span className="text-lg text-green-500 font-bold uppercase">Vital Reg</span>
          </div>

          <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
          <div className="ml-4">
            <div className="flex align-items-center">
              <div className="flex align-items-center justify-content-center bg-gray-100 border-1 border-300" style={{ width: '3rem', height: '3rem', borderRadius: '100px' }}>
                <i className="pi pi-user text-primary text-2xl"></i>
              </div>
              <span className="ml-2">
                <div className="text-md font-semibold text-gray-900 uppercase">{formatUserName(userInfo?.UserName)}</div>
                <div className="text-sm text-gray-700">DCR</div>
              </span>
            </div>
          </div>
          <hr className="mt-3 mx-0 border-top-1 border-bottom-none border-300" />

          <div className="flex flex-col bg-white mx-0 overflow-y-auto">
            <ul className="list-none p-0 px-4 w-full">
              {userInfo?.permission?.permissions.includes(
                "Can_List_Attestation"
              ) && (
                  <li>
                    <NavLink
                      style={{ borderRadius: '4px' }}
                      to="/"
                      className={({ isActive }) =>
                        `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <span>
                          <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                          <span>Birth Attestations</span>
                        </span>
                      )}
                    </NavLink>
                  </li>
                )}

              {userInfo?.permission?.permissions.includes(
                "Can_List_Modification"
              ) && (
                  <li>
                    <div style={{ borderRadius: '4px' }}
                      onClick={toggleModificationsMenu}
                      className="flex items-center p-2 hover:bg-gray-100 rounded-md transition-colors cursor-pointer mb-2"
                    >
                      <span className="font-medium text-gray-900"><i className="pi pi-user-edit text-green-600 mr-2"></i><span>Modifications</span></span>
                      <i className={`pi ${isModificationsOpen ? 'pi-chevron-up' : 'pi-chevron-down'} ml-auto text-gray-500`}></i>
                    </div>

                    {isModificationsOpen && (
                      <ul className="list-none pl-4 space-y-2 mt-2">
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/modify-birth"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-chart-line mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i><span>Birth Registrations</span></span>
                            )}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/modify-attest"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-chart-line mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                                <span>Birth Attestations</span>
                              </span>
                            )}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/modify-death"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-chart-line mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                                <span>Death Registrations</span>
                              </span>
                            )}
                          </NavLink>
                        </li>
                      </ul>
                    )}

                    {userInfo?.permission?.permissions.includes(
                      "Can_List_Birth"
                    ) && (
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/birth"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                                <span>Birth Registrations</span>
                              </span>
                            )}
                          </NavLink>
                        </li>
                      )}

                    {userInfo?.permission?.permissions.includes(
                      "Can_List_Death"
                    ) && (
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/death"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                                <span>Death registrations</span>
                              </span>
                            )}
                          </NavLink>
                        </li>
                      )}

                    {/* REISSUANCE MENU START */}
                    <div style={{ borderRadius: '4px' }}
                      onClick={toggleBirthCertReissuanceMenu}
                      className="flex items-center p-2 hover:bg-gray-100 rounded-md transition-colors cursor-pointer mb-2"
                    >
                      <span className="font-medium text-gray-900"><i className="pi pi-spinner text-green-600 mr-2"></i><span>Reissuance</span></span>
                      <i className={`pi ${isBirthCertReissuanceOpen ? 'pi-chevron-up' : 'pi-chevron-down'} ml-auto text-gray-500`}></i>
                    </div>

                    {isBirthCertReissuanceOpen && (
                      <ul className="list-none pl-4 space-y-2 mt-2">
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/birth-cert-reissuance-list"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i><span>Birth Certificates</span></span>
                            )}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/modify-attest"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                                <span>Death Certificates</span>
                              </span>
                            )}
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {/* REISSUANCE MENU END */}

                    {userInfo?.permission?.permissions.includes(
                      "Can_List_Stillbirth"
                    ) && (
                        <li>
                          <NavLink
                            style={{ borderRadius: '4px' }}
                            to="/still"
                            className={({ isActive }) =>
                              `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                              }`
                            }
                          >
                            {({ isActive }) => (
                              <span>
                                <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                                <span>Still birth registrations</span>
                              </span>
                            )}
                          </NavLink>
                        </li>
                      )}

                    {userInfo?.roles?.id === USER_ROLE.DCR && (
                      <li>
                        <NavLink
                          style={{ borderRadius: '4px' }}
                          to="/registrars"
                          className={({ isActive }) =>
                            `flex items-center p-2 rounded-md transition-colors mb-2 ${isActive ? "bg-green-600 text-white c-shadow" : "hover:bg-gray-100 text-gray-900"
                            }`
                          }
                        >
                          {({ isActive }) => (
                            <span>
                              <i className={`pi pi-file mr-2 ${isActive ? "text-white" : "text-green-600"}`}></i>
                              <span>Registrars</span>
                            </span>
                          )}
                        </NavLink>
                      </li>
                    )}

                  </li>
                )}

              <div
                onClick={toggleOtherAccessMenu}
                className="flex items-center p-2 hover:bg-gray-100 rounded-md transition-colors cursor-pointer mb-2"
              >
                <span className="font-medium text-gray-900"><i className="pi pi-plus text-green-600 mr-2"></i><span>Other Access</span></span>
                <i className={`pi ${isOtherAccessOpen ? 'pi-chevron-up' : 'pi-chevron-down'} ml-auto text-gray-500`}></i>
              </div>

              {isOtherAccessOpen && (
                <ul className="list-none pl-4 space-y-2 mt-2">
                  {hasAdminAccess && (
                    <li>
                      <NavLink
                        to={`${process.env.REACT_APP_ADMIN_NavLink}/login?t=${token}`}
                        target="_blank"
                        className="flex items-center p-2 hover:bg-gray-100 border-round transition-colors mb-2"
                      >
                        <span className="font-medium text-gray-900"><i className="pi pi-chart-line text-green-600 mr-2"></i><span>Admin{" "}</span></span>
                      </NavLink>
                    </li>
                  )}

                  {hasRegistrarAccess && (
                    <li>
                      <NavLink
                        to={`${process.env.REACT_APP_PROD_NavLink}/login?t=${token}`}
                        target="_blank"
                        className="flex items-center p-2 hover:bg-gray-100 border-round transition-colors mb-2"
                      >
                        <span className="font-medium text-gray-900"><i className="pi pi-chart-line text-green-600 mr-2"></i><span>Registrar{" "}</span></span>
                      </NavLink>
                    </li>
                  )}

                </ul>
              )}

              <li onClick={handleLogout}>
                <span
                  className="flex items-center p-2 hover:bg-gray-100 rounded-md transition-colors"
                >

                  <span className="font-medium text-gray-900"><i className="pi pi-sign-out text-green-600 mr-2"></i><span>Logout</span></span>
                </span>
              </li>
            </ul>
          </div>
          <footer className="text-center py-2 mt-auto" style={{ backgroundColor: "#ffffff" }}>
            <p className="text-xs text-gray-500">v {packageJson.version}</p>
          </footer>
        </div>
      </div>
      <div className="min-h-screen flex flex-column relative flex-auto">
        <div
          className="flex justify-content-between align-items-center px-5  shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-2"
          style={{ height: "63px", backgroundColor: "#0E7706" }}
        >
          <div className="flex">
            <StyleClass
              nodeRef={btnRef4}
              selector="#app-sidebar-1"
              enterClassName="hidden"
              enterActiveClassName="fadeinleft"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeoutleft"
              hideOnOutsideClick
            >
              {/* eslint-disable-next-line */}
              <a
                ref={btnRef4}
                className="p-ripple cursor-pointer block lg:hidden text-white mr-3"
              >
                <i className="pi pi-bars text-4xl"></i>
                <Ripple />
              </a>
            </StyleClass>
          </div>
          <StyleClass
            nodeRef={btnRef5}
            selector="@next"
            enterClassName="hidden"
            enterActiveClassName="fadein"
            leaveToClassName="hidden"
            leaveActiveClassName="fadeout"
            hideOnOutsideClick
          >
            {/* eslint-disable-next-line */}
            <a
              ref={btnRef5}
              className="p-ripple cursor-pointer block lg:hidden text-700"
            >
              <i className="pi pi-ellipsis-v text-2xl"></i>
              <Ripple />
            </a>
          </StyleClass>
        </div>
        <div className="p-5 flex flex-column flex-auto">
          <div className=" flex-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homez;

import { KEYS, PrintStatus, RegTypeOptions } from "./helpers/constants";
export const dataOptions = {
  gender: [
    { Gender_ID: 1, Gender: "Male", Desc: "MALE - MAN" },
    { Gender_ID: 2, Gender: "Female", Desc: "FEMALE - WOMAN" },
  ],
  birthOrder: [
    { Birth_Order_ID: 1, Birth_Order: 1, Desc: "1ST CHILD" },
    { Birth_Order_ID: 2, Birth_Order: 2, Desc: "2ND CHILD" },
    { Birth_Order_ID: 3, Birth_Order: 3, Desc: "3RD CHILD" },
    { Birth_Order_ID: 4, Birth_Order: 4, Desc: "4TH CHILD" },
    { Birth_Order_ID: 5, Birth_Order: 5, Desc: "5TH CHILD" },
    { Birth_Order_ID: 6, Birth_Order: 6, Desc: "6TH CHILD" },
    { Birth_Order_ID: 7, Birth_Order: 7, Desc: "7TH CHILD" },
    { Birth_Order_ID: 8, Birth_Order: 8, Desc: "8TH CHILD" },
    { Birth_Order_ID: 9, Birth_Order: 9, Desc: "9TH CHILD" },
    { Birth_Order_ID: 10, Birth_Order: 10, Desc: "10TH CHILD" },
    { Birth_Order_ID: 11, Birth_Order: 11, Desc: "11TH CHILD" },
    { Birth_Order_ID: 12, Birth_Order: 12, Desc: "12TH CHILD" },
    { Birth_Order_ID: 13, Birth_Order: 13, Desc: "13TH CHILD" },
    { Birth_Order_ID: 14, Birth_Order: 14, Desc: "14TH CHILD" },
    { Birth_Order_ID: 15, Birth_Order: 15, Desc: "15TH CHILD" },
    { Birth_Order_ID: 16, Birth_Order: 16, Desc: "16TH CHILD" },
    { Birth_Order_ID: 17, Birth_Order: 17, Desc: "17TH CHILD" },
    { Birth_Order_ID: 18, Birth_Order: 18, Desc: "18TH CHILD" },
    { Birth_Order_ID: 19, Birth_Order: 19, Desc: "19TH CHILD" },
    { Birth_Order_ID: 20, Birth_Order: 20, Desc: "20TH CHILD" },
  ],
  birthType: [
    { Birth_Type_ID: 1, Description: "Single" },
    { Birth_Type_ID: 2, Description: "Twins" },
    { Birth_Type_ID: 3, Description: "Triplets" },
    { Birth_Type_ID: 4, Description: null },
    { Birth_Type_ID: 5, Description: null },
    { Birth_Type_ID: 6, Description: null },
  ],
  birthPlace: [
    { BirthPlace_ID: 1, BirthPlace_Desc: "Hospital" },
    { BirthPlace_ID: 2, BirthPlace_Desc: "At Home" },
    { BirthPlace_ID: 3, BirthPlace_Desc: "Maternity Home" },
    { BirthPlace_ID: 4, BirthPlace_Desc: "Car/Taxi" },
    { BirthPlace_ID: 5, BirthPlace_Desc: "Bus" },
    { BirthPlace_ID: 6, BirthPlace_Desc: "Traditional Maternity Homes" },
    { BirthPlace_ID: 7, BirthPlace_Desc: "Train" },
    { BirthPlace_ID: 8, BirthPlace_Desc: "Spritual Homes" },
    { BirthPlace_ID: 9, BirthPlace_Desc: "Traditional Birth Attendance" },
    { BirthPlace_ID: 10, BirthPlace_Desc: "Others" },
    { BirthPlace_ID: 11, BirthPlace_Desc: "Roadside" },
    { BirthPlace_ID: 12, BirthPlace_Desc: "Traditional Herbalist" },
    { BirthPlace_ID: 13, BirthPlace_Desc: "Ship" },
    { BirthPlace_ID: 14, BirthPlace_Desc: "Aeroplane" },
  ],
};

export const dateFormatter = (inputDateString) => {
  const date = new Date(inputDateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};

export const typeOptions = [
  { label: "Approved", value: "approved" },
  { label: "Queried", value: "queried" },
  { label: "Pending", value: "pending" },
];

export const userHasPermissions = (requiredPermissions) => {
  const userInfo = JSON.parse(localStorage.getItem(KEYS.user));

  return requiredPermissions.every((permission) =>
    userInfo?.permission?.permissions.includes(permission)
  );
};

export const USER_ROLE = {
  STATE_DIRECTOR: "1",
  DCR: "2",
  REGISTRAR: "3",
  ADMIN: "5",
  SUBADMIN: "8",
};

export const RoleName = (roleId) => {
  switch (Number(roleId)) {
    case 1:
      return "State Director";
    case 2:
      return "DCR";
    case 3:
      return "Registrar";
    case 4:
      return "Adhoc";
    case 5:
      return "Admin";
    case 6:
      return "Auth user";
    case 7:
      return "Hospital";
    case 8:
      return "Sub Admin";
    case 9:
      return "TIU";
    case 10:
      return "State Commissioner";
    default:
      return "unknown";
  }
};

export const approvalStatus = {
  PENDING: 0,
  APPROVED: 1,
  QUERIED: 2,
};

export const modField = {
  child_name: "child_name",
  father_name: "father_name",
  mother_name: "mother_name",
  child_sex: "child_sex",
  deceased_name: "deceased_name",
  deceased_sex: "deceased_sex",
  deceased_age_at_death: "deceased_age_at_death",
  deceased_date_of_death: "deceased_date_of_death",
  applicant_name: "applicant_name",
  applicant_sex: "applicant_sex",
  applicant_town: "applicant_town",
  applicant_face: "face",
};

export const Mod = {
  Neutral: 0,
  Pending: 1,
  Approved: 2,
  Queried: 3,
};
export const ModStatus = {
  0: "Neutral",
  1: "Pending",
  2: "Approved",
  3: "Queried",
};

export const getStatus = (status) => {
  let response = "";
  // eslint-disable-next-line
  if (status == Mod.Neutral) {
    response = "Neutral";
  }
  // eslint-disable-next-line
  if (status == Mod.Pending) {
    response = "Pending";
  }
  // eslint-disable-next-line
  if (status == Mod.Approved) {
    response = "Approved";
  }
  // eslint-disable-next-line
  if (status == Mod.Queried) {
    response = "Queried";
  }
  return response;
};

export const showDeceasedDetails = (field) => {
  if (
    field === modField.deceased_name ||
    field === modField.deceased_sex ||
    field === modField.deceased_age_at_death ||
    field === modField.deceased_date_of_death
  ) {
    return true;
  } else {
    return false;
  }
};

export const SerialNumberTemplate = (rowData, serials, items) => {
  const itemIndex = items.findIndex((item, index) => item === rowData);
  
  return <p>{serials[itemIndex]}</p>;
};

export const printStatusTemplate = (rowData) => {
  switch (+rowData) {
    case PrintStatus.Printed:
      return <p className="font-bold">Printed</p>;
    default:
      return <p className="font-bold"> Not Printed</p>;
  }
};

export const regTypeFormat = (rowData) => {
  switch (+rowData) {
    case RegTypeOptions.SelfService:
      return <p className={`font-bold`}>Self Service</p>;
    default:
      return <p className={`font-bold`}> Assisted</p>;
  }
};
export const statusFormat = (rowData) => {
  let status;
  switch (+rowData) {
    case approvalStatus.APPROVED:
      status = "Approved";
      break;
    case approvalStatus.PENDING:
      status = "Pending";
      break;
    case approvalStatus.QUERIED:
      status = "Queried";
      break;
    default:
      status = "Unknown";
  }
  return <p>{status}</p>;
};

export const reverse_option_value = (value, data) => {
  let result = data.find((x) => +x.value === +value);
  return result?.name || "";
};

export const getBgcolor = (status) => {
  let color;
  switch (status) {
    case approvalStatus.APPROVED:
      color = "bg-green-600";
      break;
    case approvalStatus.QUERIED:
      color = "bg-red-600";
      break;
    case approvalStatus.PENDING:
      color = "bg-yellow-600";
      break;

    default:
      color = "bg-yellow-600";
      break;
  }
  return color;
};

export const getApprovalStatusKey = (value) => {
  const key = Object.keys(approvalStatus).find(
    (key) => approvalStatus[key] === value
  );
  return key || "Unknown Status";
};

export const AdminAccessList = [
  "Can_See_Death",
  "Can_See_Birth",
  "Can_See_Modification",
  "Can_See_Attestation",
];
export const RegistrarAccessList = [
  "Can_See_Attestation",
  "Can_See_Birth",
  "Can_See_Death",
  "Can_See_Stillbirth",
  "Can_See_Modification",
];

export const capitalizeWords = (str) => {
  if (!str) return ''; // Return an empty string if input is null or undefined
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export function formatUserName(userName) {
  return userName.replace(/-/g, ' ');
}
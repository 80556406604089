import { useState, useCallback } from "react";
import { getFilePreviewUrl } from "../../redux/apiCalls";

export const useGetFileUrl = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getUrl = useCallback(async (key) => {
        setLoading(true);
        setError(null);

        try {
            const response = await getFilePreviewUrl(key);

            if (response && response.url) {
                return response.url;
            } else {
                throw new Error("File preview URL not found.");
            }
        } catch (err) {
            console.error("Error fetching file URL:", err);
            setError(err.message || "An error occurred while fetching the file URL.");
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    return { getUrl, isLoadingUrl: loading, error };
};

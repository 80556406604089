import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";

import Login from "./pages/Login";
import SingleApplication from "./pages/SingleApplication";
import ProtectedRouteAdmin from "./pages/ProtectedRouteAdmin";
import ProtectedRouteAdmin3 from "./pages/ProtectedRouteAdmin3";
import Homez from "./components/Homez";

import PrivateHos from "./pages/Private";

import RegList from "./pages/RegistrarsList";
import SingleRegistrars from "./pages/Single-registrars";

import { ToastContainer } from "react-toastify";

import ModifyAttest from "./pages/ModifyAttest";

import SingleModifiedAttestation from "./pages/SingleModifiedAttestation";
import ModifyBirth from "./pages/ModifyBirth";
import SingleModifiedBirth from "./pages/SingleModifiedBirth";

import AdminVerified from "./pages/AdminVerified";
import SingleBirth from "./pages/SingleBirth";
import AdminVerifiedDeath from "./pages/AdminVerifiedDeath";
import SingleApplication2 from "./pages/SingleApplication2";
import AdminVerifiedReg from "./pages/AdminVerifiedReg";
import Still from "./pages/Still";
import SingleStill from "./pages/SingleStill";
import ModifyDeath from "./pages/ModifyDeath";
import SingleModifiedDeath from "./pages/SingleModifiedDeath";

import Unauthorized from "./pages/Unauthorized";
import { useSelector } from "react-redux";
import BulkPrintBirthCertificate from "./pages/BulkPrintCertificate";
import BirthCertificateReissuanceList from "./pages/BirthCertificateReissunceList";
import BirthCertificateReissuanceDetails from "./pages/BirthCertificateReissuanceDetails";
const App = () => {
  const { userInfo } = useSelector((state) => state.user);

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRouteAdmin>
              <Homez />
            </ProtectedRouteAdmin>
          }
        >
          <Route
            index
            element={
              userInfo?.roles?.name === "DCR" ? (
                <AdminVerified />
              ) : (
                <PrivateHos />
              )
            }
          />
          <Route path="modify-birth" element={<ModifyBirth />} />
          <Route path="modify-death" element={<ModifyDeath />} />
          <Route path="modify-attest" element={<ModifyAttest />} />
          <Route
            path="single-attest/:id"
            element={<SingleModifiedAttestation />}
          />
          <Route path="single-birth/:id" element={<SingleModifiedBirth />} />
          <Route path="single-death/:id" element={<SingleModifiedDeath />} />
          <Route path="single/:id" element={<SingleApplication />} />

          <Route path="birth" element={<AdminVerified />} />

          <Route path="singleBirth/:id" element={<SingleBirth />} />

          <Route path="still" element={<Still />} />

          <Route path="still/:id" element={<SingleStill />} />

          <Route path="death" element={<AdminVerifiedDeath />} />
          <Route path="registrars" element={<AdminVerifiedReg />} />

          <Route path="birth-cert-reissuance-list" element={<BirthCertificateReissuanceList />} />
          <Route path="birth-cert-reissuance/details/:id" element={<BirthCertificateReissuanceDetails />} />

          <Route
            path="/birth/bulk-print"
            element={<BulkPrintBirthCertificate />}
          />
          <Route path="singleDeath/:id" element={<SingleApplication2 />} />

          <Route
            path="single-registrars/:id"
            element={
              <ProtectedRouteAdmin3>
                <SingleRegistrars />
              </ProtectedRouteAdmin3>
            }
          />

          <Route
            path="registrars-list/:id"
            element={
              <ProtectedRouteAdmin3>
                <RegList />
              </ProtectedRouteAdmin3>
            }
          />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="unauth" element={<Unauthorized />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import authFetch from "../axios";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useGetFileUrl } from "../helpers/hooks/useGetFIleUrl";

const BirthCertificateReissuanceDetails = () => {
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [faceFileKey, setFaceFileKey] = useState("");
    const [documentFileKey, setDocumentFileKey] = useState("");
    const [faceUrl, setFaceUrl] = useState("");
    const [documentUrl, setDocumentUrl] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const { getUrl: getFileUrl, isLoadingUrl: isLoadingFileUrl } = useGetFileUrl();

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        const fetchDetails = async () => {
            try {
                setIsLoading(true);
                setHasError(false);
                const payload = {
                    certType: "birth_reissuance",
                    id: Number(id),
                };
                const response = await authFetch.post(`/certificate-reissuance/single`, payload);

                const data = response?.data?.data;
                setDetails(data);

                // Set keys from the response
                setFaceFileKey(data?.childFace);
                setDocumentFileKey(data?.supportingDocument?.[0]?.file);
            } catch (error) {
                console.error("Error fetching details:", error);
                setHasError(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDetails();
    }, [id]);

    useEffect(() => {
        const fetchFileUrls = async () => {
            try {
                // Fetch face file URL
                if (faceFileKey) {
                    const faceUrl = await getFileUrl(faceFileKey);
                    setFaceUrl(faceUrl);
                }

                // Fetch document file URL
                if (documentFileKey) {
                    const docUrl = await getFileUrl(documentFileKey);
                    setDocumentUrl(docUrl);
                }
            } catch (error) {
                console.error("Error fetching file URLs:", error);
            }
        };

        fetchFileUrls();
    }, [faceFileKey, documentFileKey, getFileUrl]);

    const renderDataField = (label, value) => (
        <div className="col-12 md:col-3 mb-2">
            <div className="text-600 text-xs">{label}</div>
            <div className="text-900 font-medium">
                {value ? (
                    value
                ) : (
                    <span className="text-gray-500 text-xs italic flex items-center">
                        <i className="pi pi-exclamation-triangle mr-1 text-red-500" style={{ fontSize: '1rem' }}></i>
                        Data not provided
                    </span>
                )}
            </div>
        </div>
    );

    if (isLoading) {
        return (
            <div className="px-2">
                <Button
                    label="Back"
                    icon="pi pi-arrow-left"
                    className="p-button-text p-button-secondary mb-2 p-button-rounded"
                    onClick={goBack}
                />
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="px-2">
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-text p-button-secondary mb-2 p-button-rounded" onClick={goBack} />
                <p className="text-red-500">Failed to load details. Please try again later.</p>
            </div>
        );
    }

    return (
        <>
            <Button label="Back" icon="pi pi-arrow-left" className="p-button-text p-button-secondary mb-2 p-button-rounded" onClick={goBack} />
            <div className="px-2">
                <div className="bg-yellow-600 px-4 py-2 shadow-2 border-round mb-4">
                    <div className="text-white font-bold">Registration Details</div>
                </div>

                <div>
                    {/* Child's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Child</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("Surname of Child", details?.childSurname)}
                            {renderDataField("First Name of Child", details?.childFirstName)}
                            {renderDataField("Middle Name of Child", details?.childMiddleName)}
                            {renderDataField("Reference", details?.reference)}
                            {renderDataField("Date of Birth", details?.dateOfBirth)}
                            {renderDataField("Sex", details?.childSexData?.gender)}
                            {renderDataField("Place of Birth", details?.placeOfBirthData?.BirthPlace_Desc)}
                            {renderDataField("Birth Type", details?.birthTypeData?.Description)}
                        </div>
                        <div className="text-600 text-xs">Child Face</div>
                        {isLoadingFileUrl ? (
                            <Skeleton width="150px" height="150px" />
                        ) : faceUrl ? (
                            <Image src={faceUrl} zoomSrc={faceUrl} alt="Child Face" preview width="100%" />
                        ) : (
                            <p>No face image available.</p>
                        )}
                    </div>

                    {/* Mother's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Mother</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("First Name", details?.motherFirstName)}
                            {renderDataField("Surname", details?.motherSurname)}
                            {renderDataField("Maiden Name", details?.motherMaidenName)}
                            {renderDataField("Age at Birth", details?.motherAgeAtBirth)}
                            {renderDataField("Marital Status", details?.motherMaritalStatusData?.Status_Desc)}
                            {renderDataField("Phone Number", details?.motherPhoneNumber)}
                            {renderDataField("Address", details?.motherResidenceAddress)}
                            {renderDataField("Nationality", details?.motherCountryOfResidenceData?.Country_Name)}
                        </div>
                    </div>

                    {/* Father's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Father</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("First Name", details?.fatherFirstName)}
                            {renderDataField("Surname", details?.fatherSurname)}
                            {renderDataField("Age at Birth", details?.fatherAgeAtBirth)}
                            {renderDataField("Marital Status", details?.fatherMaritalStatusData?.Status_Desc)}
                            {renderDataField("Phone Number", details?.fatherPhoneNumber)}
                            {renderDataField("Address", details?.fatherResidenceAddress)}
                            {renderDataField("Nationality", details?.fatherCountryOfResidenceData?.Country_Name)}
                        </div>
                    </div>

                    {/* Additional Information */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Additional Information</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("NIN", details?.nin)}
                            {renderDataField("Legacy Certificate Number", details?.legacyCertNumber)}
                            {renderDataField("Approval Status", details?.approvedStatus === "1" ? "Approved" : "Pending")}
                        </div>
                    </div>

                    {/* Uploaded Document Information */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Uploaded Document</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid capitalize">
                            {renderDataField("Document Type", details?.supportingDocument?.[0]?.type)}
                            <div>
                                {/* Render preview images */}
                                <div className="text-600 text-xs">Document Preview</div>
                                {isLoadingFileUrl ? (
                                    <Skeleton width="150px" height="150px" />
                                ) : documentUrl ? (
                                    <Image src={documentUrl} zoomSrc={documentUrl} alt="Supporting Document" preview width="150px" height="150px" />
                                ) : (
                                    <p>No document available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            <Button label="Approve" className="mr-2 p-button-raised" style={{ width: '150px' }} />
                            <Button label="Query" className="p-button-danger p-button-raised" style={{ width: '150px' }} />
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default BirthCertificateReissuanceDetails;